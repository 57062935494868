import styled from 'styled-components'

const EditButtons = styled.div`
position: absolute;
top: 2rem;
right: 1.5rem;

i {
  margin-left: 0.5rem;
}
`

export default EditButtons